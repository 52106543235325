<template>
  <div class="container">
    <div class="storyInfo">
      <img class="pic" :src="storyInfo.headerPic" />
      <img class="pic" :src="storyInfo.headerPic2" />
      <div class="article">
        <div class="content" v-html="language === 'cn' ? storyInfo.cnContent : storyInfo.enContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { jsonRequest } from '../../utils/request'
import { PathEnum } from '../../enum/pathEnum'
export default {
  name: 'storyInfo',
  components: {},
  computed: {
    language() {
      return this.$store.state.language
    }
  },
  data() {
    return {
      storyInfo: {}
    }
  },
  async mounted() {
    const { data } = await jsonRequest(PathEnum.STORY)
    this.storyInfo = data
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.storyInfo {
  .pic {
    width: 100%;
  }
  .article {
    width: 980px;
    margin: 0 auto;
    .title {
      font-size: 30px;
      line-height: 30px;
      padding: 50px 0;
      text-align: center;
    }
    ::v-deep .content {
      font-size: 16px;
      line-height: 28px;
      white-space: pre-wrap;
      text-align: center;
    }
    img {
      width: 100%;
    }
  }
}
</style>
